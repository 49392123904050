import React from 'react';
import { PathService } from '../../services';
import { TemplateProps, VideoViewContext } from '../../types';
import { OurInsightsVideoView } from '../../views';

const OurInsightVideos: React.FunctionComponent<
  TemplateProps<VideoViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return <OurInsightsVideoView video={JSON.parse(pageContext.video)} />;
};

export default OurInsightVideos;
